.blog-main-articles
  
  & .wrapper
    height 100%
  
  &__slider
    padding-bottom remSize(56px)
    overflow hidden
    
    @media (max-width small-desktop)
      padding-bottom remSize(90px)
    
    @media (max-width large-phone-2)
      padding-bottom remSize(140px)

  &__slider-wrapper
    position relative
    display flex
    width 100%
    height 100%
    box-sizing content-box
  
  &__slider-slide
    position relative
    flex-shrink 0
  
  &__slider-slide-img
    fullSize()
    width 100%
    height @width
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__slider-slide-info
    centered("bottom-center")
    bottom remSize(-52px)
    padding remSize(30px) remSize(62px) remSize(76px)
    max-width 96%
    width remSize(960px)
    background-color white
    box-sizing border-box
    box-shadow 0 0 remSize(5px) 0 shadow
    
    @media (max-width small-desktop)
      padding remSize(26px) remSize(56px) remSize(72px)
      width remSize(880px)
    
    @media (max-width medium-tablet-3)
      padding remSize(26px) remSize(46px) remSize(60px)
      width remSize(660px)
    
    @media (max-width small-tablet)
      padding remSize(20px) remSize(36px) remSize(60px)
      width remSize(420px)
      bottom remSize(-88px)
    
    @media (max-width large-phone-2)
      margin remSize(10px) auto 0
      padding remSize(16px) remSize(28px) remSize(32px)
      position static
      transform translateX(0)
  
  &__slider-slide-title
    margin 0 auto remSize(18px)
    display block
    max-width 100%
    width remSize(600px)
    font-family header-font
    font-size remSize(28px)
    font-weight 700
    text-align center
    
    @media (max-width small-desktop)
      font-size remSize(24px)
    
    @media (max-width large-phone-2)
      font-size remSize(20px)
    
    @media (max-width medium-phone)
      font-size remSize(17px)
    
    &:hover
      color main
  
  &__slider-slide-section
    display block
    font-size remSize(20px)
    color light-blue
    text-align center
    
    @media (max-width small-desktop)
      font-size remSize(18px)
    
    @media (max-width large-phone-2)
      font-size remSize(15px)
    
    &:hover 
      color dark-blue
  
  &__slider-pagination
    margin-top remSize(-48px)
    display flex
    justify-content center
    
    @media (max-width small-desktop)
      margin-top remSize(-40px)
    
    @media (max-width large-phone-2)
      margin-top remSize(24px)
  
  &__slider-bullet
    margin-right remSize(6px)
    display block
    width remSize(12px)
    height @width
    background-color gray
    border-radius 50%
    transition background-color t-default
    cursor pointer
    
    &:hover
      background-color dark-blue
    
    &.is-active
      background-color light-blue
    
    &:last-child
      margin-right 0