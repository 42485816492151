.footer
  padding remSize(36px) 0 remSize(48px)
  border-top remSize(2px) solid dark-blue
  
  & .wrapper
    display flex
  
  &__col
    padding 0 remSize(16px)
    colWidth(1, 5)
  
    @media (max-width large-tablet)
      colWidth(1, 3)
    
    &--list
      
      @media (max-width large-tablet)
        display none
    
    &--list-mobile
      display none
      
      @media (max-width large-tablet)
        display block
  
  &__logo
    margin-bottom remSize(6px)
    display block
    font-size remSize(32px)
    font-weight 900
  
  &__slogan
    margin-bottom remSize(16px)
    line-height 1.5
    font-size remSize(14px)
    font-weight 700
    text-transform uppercase
    letter-spacing remSize(0.5px)
  
  &__socials
    display flex
  
  &__socials-link
    margin-right remSize(6px)
    position relative
    width remSize(28px)
    height @width
    background-color dark-blue
    border-radius default-radius
    
    &:last-child
      margin-right 0
    
    &:hover
      background-color light-blue
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
  
  &__subtitle
    margin-bottom remSize(24px)
    font-size remSize(18px)
    font-weight 700
  
  &__phones
    margin remSize(32px) 0 remSize(16px)
    font-size remSize(15px)
  
  &__phone-row
    margin-bottom remSize(10px)
    
    &:last-child
      margin-bottom 0
  
  &__phone-title
    margin-right remSize(8px)
    display inline-block
  
  &__phone
    font-weight 700
    
    &:hover
      color light-blue
  
  &__address-block
    font-size remSize(15px)
  
  &__address-title
    margin-bottom remSize(8px)
    display block
    font-weight 700
  
  &__address
    max-width 100%
    width remSize(190px)
  
  &__nav-list
    font-size remSize(15px)
  
  &__nav-item
    margin-bottom remSize(4px)
    
    &:last-child
      margin-bottom 0
  
  &__nav-link
    
    &:hover
      color light-blue