a
  color dark-blue
  text-decoration none

  &:not([class])
    color light-blue
    text-decoration underline
    
    &:hover
      color dark-blue
      text-decoration none