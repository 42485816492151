.preview-catalogs
  
  & .wrapper
    display flex
  
  &__item
    position relative
    colWidth(1, 2, 16px)
    
    &:hover
      
      & ^[0]__item-title-wrapper
        background-color alpha(white, 0.9)
      
      & ^[0]__item-title
        color light-blue
        border-color light-blue
    
    &:first-child
      margin-right remSize(16px)
    
    &:last-child
      margin-left remSize(16px)
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__item-title-wrapper
    padding remSize(16px)
    centered("wrong-center")
    top 58%
    max-width 90%
    width remSize(420px)
    color dark-text
    text-transform uppercase
    text-align center
    background-color alpha(white, 0.8)
    box-sizing border-box
  
  &__item-title
    padding remSize(12px)
    line-height 1.4
    font-weight 400
    letter-spacing remSize(1px)
    border remSize(3px) solid dark-text