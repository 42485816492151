.catalog-title
  margin remSize(18px) 0 remSize(38px)
  
  &__title
    margin-bottom remSize(2px)
    max-width 100%
    width remSize(760px)
    font-size remSize(24px)
    font-weight 700
  
  &__note
    font-size remSize(18px)
  
  &__offers-list
    margin-top remSize(14px)
    display flex
  
  &__offers-item
    margin-right remSize(48px)
    display flex
    
    &:last-child
      margin-right 0
  
  &__offers-item-title
    margin-right remSize(8px)
  
  &__offers-item-description
    color light-blue