.article-navigation
  
  & .wrapper
    display flex
    
    @media (max-width small-tablet)
      flex-wrap wrap
  
  &__item
    position relative
    color white
    colWidth(1, 2, 30px)
    
    @media (max-width large-tablet)
      colWidth(1, 2, 16px)
    
    @media (max-width small-tablet)
      width 100%
    
    &:hover
      
      & ^[0]__item-img-overlay
        background-color alpha(black, 0.85)
      
      & ^[0]__item-title
        color light-blue
      
      & ^[0]__item-arr
        border-color light-blue
        
      & ^[0]__item-arr-icon
        fill light-blue !important
    
    &--prev
      margin-right remSize(30px)
      
      @media (max-width large-tablet)
        margin-right remSize(16px)
      
      @media (max-width small-tablet)
        margin-right 0
      
      & ^[0]__item-arr
        left remSize(30px)
        
        @media (max-width medium-tablet)
          left remSize(16px)    
        
        @media (max-width small-tablet)
          left remSize(32px)
        
        @media (max-width large-phone-2)
          left remSize(24px)
    
    &--next
      margin-left remSize(30px)
      
      @media (max-width large-tablet)
        margin-left remSize(16px)
      
      @media (max-width small-tablet)
        margin-left 0
      
      & ^[0]__item-arr
        right remSize(30px)
        
        @media (max-width medium-tablet)
          right remSize(16px)
        
        @media (max-width small-tablet)
          right remSize(32px)
        
        @media (max-width large-phone-2)
          right remSize(24px)       
  
  &__item-img-wrapper
    position relative
  
  &__item-img-overlay
    fullSize()
    background-color alpha(black, 0.5)
    z-index 5
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    z-index 0
  
  &__item-title
    position absolute
    top remSize(32px)
    left remSize(30px)
    width s("calc(100% - (%s * 2))", remSize(30px))
    line-height 1.4
    font-size remSize(26px)
    font-weight 700
    letter-spacing remSize(1.25px)
    z-index 10
    
    @media (max-width small-desktop)
      font-size remSize(22px)
    
    @media (max-width medium-tablet-3)
      top remSize(24px)
      font-size remSize(20px)
    
    @media (max-width medium-tablet)
      top remSize(20px)
      left remSize(16px)
      width s("calc(100% - (%s * 2))", remSize(16px))
      font-size remSize(18px)
    
    @media (max-width small-tablet)
      top remSize(36px)
      left remSize(32px)
      width s("calc(100% - (%s * 2))", remSize(32px))
      font-size remSize(28px)
    
    @media (max-width large-phone-2)
      top remSize(26px)
      left remSize(24px)
      width s("calc(100% - (%s * 2))", remSize(24px))
      font-size remSize(24px)
    
    @media (max-width large-phone)
      font-size remSize(20px)
    
    @media (max-width medium-phone)
      font-size remSize(18px)
  
  &__item-arr
    position absolute
    bottom remSize(36px)
    width remSize(56px)
    height @width
    border-radius 50%
    border remSize(2px) solid white
    box-sizing border-box
    z-index 10
    
    @media (max-width medium-tablet)
      width remSize(46px)
      height @width
      bottom remSize(22px)
    
    @media (max-width small-tablet)
      width remSize(60px)
      height @width
      bottom remSize(48px)
    
    @media (max-width large-phone-2)
      width remSize(50px)
      height @width
      bottom remSize(32px)
    
    @media (max-width large-phone)
      width remSize(44px)
      height @width
    
    @media (max-width medium-phone)
      width remSize(40px)
      height @width
  
  &__item-arr-icon
    centered()
    width 36%
    height @width
    fill white !important