.preview-news
  
  & .wrapper
    display flex
    flex-wrap wrap
  
  &__title
    width 100%

  &__category-list
    margin-bottom remSize(36px)
    display flex
    width 100%
  
  &__category-item
    margin-right remSize(32px)
    position relative
    padding remSize(4px) 0
    font-size remSize(18px)
    border-bottom remSize(1px) solid transparent
    cursor pointer
    
    &:after
      content ""
      position absolute
      top 50%
      right remSize(-16px)
      width remSize(1px)
      height 70%
      background-color dark-blue
      transform translateY(-50%)
    
    &:last-child
      margin-right 0
      
      &:after
        display none
    
    &:hover
      color light-blue
    
    &.is-active
      border-color dark-blue
      cursor default
      
      &:hover
        color dark-blue
  
  &__news-list
    margin-left remSize(-16px)
    colWidth(3, 4, -16px)
  
  &__news-list-content
    display none
    
    &.is-active
      display flex
      flex-wrap wrap
  
  &__news-item
    margin 0 remSize(16px) remSize(32px)
    colWidth(1, 3, 16px, 16px)
    
    & .line
      margin-top remSize(12px)
  
  &__news-item-img-wrapper
    margin-bottom remSize(12px)
    position relative
    display block
  
  &__news-item-img
    fullSize()
    background-repeat no-repaet
    background-position 50%
    background-size cover
  
  &__news-item-title
    margin-bottom remSize(8px)
    display block
    max-width 90%
    width remSize(240px)
    font-size remSize(17px)
    color dark-text
    font-weight 700
    
    &:hover
      color light-blue
  
  &__news-item-description
    max-width 90%
    width remSize(260px)
    font-size remSize(15px)
    
  &__banner-wrapper
    margin-left remSize(16px)
    colWidth(1, 4, 16px)