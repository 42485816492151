.error-404
  height remSize(320px)
  
  & .wrapper
    position relative
    height 100%
    
  &__error
    centered()
    font-family header-font
    font-size remSize(300px)
    font-weight 700
    color alpha(dark-blue, 0.2)
    z-index 0
  
  &__info
    centered("wrong-center")
    text-align center
    z-index 5
  
  &__message
    margin-bottom remSize(30px)
    font-size remSize(26px)
    font-weight 700
    color dark-text
    letter-spacing remSize(1px)
  
  &__to-main
    font-size remSize(13px)