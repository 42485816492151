.article-description
  
  &__content
    width remSize(920px)
    max-width 100%
    
    & h2
      margin remSize(24px) 0 remSize(14px)
      
      &:first-child
        margin-top 0
    
    & h3
      margin remSize(20px) 0 remSize(10px)
      
      &:first-child
        margin-top 0
    
    & ul
      margin remSize(20px) 0 remSize(26px)
      
      &:first-child
        margin-top 0