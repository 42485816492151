.breadcrumbs
  padding remSize(12px) 0
  font-size remSize(14px)
  
  &__list
    display flex
    align-items center
  
  &__link
    padding remSize(1px) 0
    box-sizing border-box
    
    &:hover
      color light-blue
    
    &.is-active
      border-bottom remSize(1px) solid dark-blue
      
      &:hover
        color dark-blue
  
  &__arrow
    margin 0 remSize(6px)
    position relative
    width remSize(16px)
    height @width
  
  &__arrow-icon
    centered()
    width 56%
    height @width