.subsections
  
  &__row
    margin-bottom remSize(32px)
    display flex
    
    &:first-child
      margin-left remSize(-16px)
      width s("calc(100% + (%s * 2))", remSize(16px))
    
    &:last-child
      margin-bottom 0
    
    & ^[0]__item
      
      &--small
        margin 0 remSize(16px)
        colWidth(1, 3, 16px, 16px)
  
  &__col
    
    &--big
      margin-right remSize(12px)
      colWidth(2, 3, 12px)
    
    &--small
      margin-left remSize(22px)
      colWidth(1, 3, 22px)
      
      & ^[0]__item
        
        &--small
          margin 0
          margin-bottom remSize(32px)
          width 100%
          
          &:last-child
            margin-bottom 0
    
  &__item
    position relative
    border remSize(1px) solid light-gray
    box-shadow 0 0 remSize(3px) remSize(-1px) shadow
    overflow hidden
    
    &--small
      display block
      height remSize(300px)
      
      &:hover
        box-shadow 0 0 remSize(4px) 0 shadow
        
        & ^[0]__item-overlay
          opacity 0.5

        & ^[0]__item-title
          color light-blue
    
    &--big
      height 100%
      
      & ^[0]__item-title
        margin-bottom remSize(100px)
        padding remSize(16px) remSize(48px) remSize(18px)
        font-size remSize(36px)
        color dark-blue
        background-color white
        text-shadow none
        box-shadow 0 0 remSize(4px) remSize(1px) shadow
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    z-index -4
  
  &__item-overlay
    fullSize()
    background-color img-overlay
    z-index -2
  
  &__item-title
    margin remSize(100px) 0 remSize(8px)
    padding 0 remSize(32px)
    line-height 1.4
    font-size remSize(22px)
    font-weight 700
    color white
    text-align center
    text-transform uppercase
    text-shadow remSize(2px) remSize(2px) remSize(3px) text-shadow
    letter-spacing remSize(1px)
  
  &__item-count
    padding 0 remSize(32px)
    font-size remSize(18px)
    color light-blue
    text-align center
    text-shadow remSize(2px) remSize(2px) remSize(3px) text-shadow
    letter-spacing remSize(1px)
  
  &__item-list
    margin 0 auto remSize(48px)
    display flex
    justify-content space-between
    flex-wrap wrap
    max-width 90%
    width remSize(580px)
  
  &__item-link
    margin-bottom remSize(18px)
    width remSize(260px)
    font-size remSize(14px)
    text-align center
    box-shadow 0 0 remSize(4px) remSize(1px) shadow
  
  &__item-catalog-wrapper
    margin 0 auto
    display flex
    max-width 90%
    justify-content center
    width remSize(580px)
  
  &__item-catalog
    font-size remSize(18px)
    letter-spacing remSize(1px)
    