.subscribe
  padding remSize(48px) 0 remSize(60px)
  text-align center
  border-top remSize(2px) solid gray
  border-bottom @border-top
  
  &__title
    margin 0 auto remSize(32px)
    max-width 90%
    width remSize(620px)
    line-height 1.4
  
  &__form
    margin 0 auto
    max-width 90%
    width remSize(620px)
    display flex
    justify-content center
  
  &__email
    margin-right remSize(12px)
    padding remSize(10px) remSize(16px)
    width remSize(260px)