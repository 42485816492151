.complex-offers
  
  &__filters-wrapper
    margin-bottom remSize(48px)
    padding remSize(28px) remSize(32px) remSize(32px)
    position relative
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
  
  &__title
    margin-bottom remSize(24px)
  
  &__types
    margin-bottom remSize(16px)
    display flex
    flex-wrap wrap
  
  &__type
    margin-right remSize(32px)
    margin-bottom remSize(18px)
    display flex
    font-size remSize(18px)
  
  &__type-title
    margin-right remSize(6px)
    color light-blue
    cursor pointer
    
    &:hover
      color dark-blue
  
  &__type-count
    color gray
  
  &__filters
    display flex
    align-items center
  
  &__floor
    margin-right remSize(48px)
    font-size remSize(12px)
  
  &__interval
    margin-right remSize(36px)
    display flex
    align-items center
  
  &__interval-input
    margin 0 remSize(6px)
    width remSize(80px)
    border-color light-blue
    
    &::placeholder
      color light-blue
  
  &__submit
    font-size remSize(12px)
  
  &__item
    margin-bottom remSize(56px)
    display flex
    background-color white
    
    &:last-child
      margin-bottom 0
  
  &__item-img-wrapper
    position relative
    colWidth(2, 7)
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__item-info
    padding remSize(16px) remSize(24px) remSize(18px)
    colWidth(5, 7)
    box-sizing border-box
    border remSize(1px) solid light-gray
    border-left 0
  
  &__item-header
    margin-bottom remSize(16px)
    display flex
    justify-content flex-end
    flex-wrap wrap
    align-items center
    border-bottom remSize(1px) solid light-gray
    
    & .note
      margin-left remSize(8px)
      display inline-block
      font-size remSize(14px)
      font-weight 400
      color gray
  
  &__item-title
    margin-right remSize(36px)
    margin-bottom remSize(12px)
    display block
    width s("calc(100% - (%s + %s))", remSize(36px), remSize(100px))
    font-size remSize(20px)
    font-weight 700
    color light-blue
    
    &:hover
      color dark-blue
    
    & .sup
      font-size remSize(13px)
  
  &__item-id
    width remSize(100px)
    color red
    text-transform uppercase
  
  &__item-prices
    margin-right remSize(36px)
    margin-bottom remSize(16px)
    display flex
    align-items flex-end
    width s("calc(100% - (%s + %s))", remSize(320px), remSize(36px))
  
  &__item-price
    margin-right remSize(16px)
    font-size remSize(17px)
    font-weight 700
    
    & .rub
      margin-right remSize(4px)
      display inline-block
      width remSize(14px)
      height @width
  
  &__item-price-2
    font-size remSize(15px)
    
    & .rub
      margin-right remSize(4px)
      display inline-block
      width remSize(12px)
      height @width
    
    & .sup
      font-size remSize(11px)
  
  &__item-mortgage
    margin-bottom remSize(16px)
    width remSize(320px)
    font-size remSize(15px)
    font-weight 700
    color red
    text-align right
    text-decoration underline
    
    &:hover
      color dark-blue
      text-decoration none
  
  &__item-likes
    margin-bottom remSize(16px)
    display flex
    justify-content flex-end
    align-items center
    width remSize(320px)
    font-size remSize(14px)
    cursor pointer
    
    &:hover
      color light-blue
      
      & ^[0]__item-likes-icon
        stroke light-blue
  
  &__item-likes-icon
    margin-right remSize(8px)
    width remSize(15px)
    height @width
    fill transparent !important
    stroke dark-blue
  
  &__item-description
    margin-bottom remSize(28px)
    max-width 100%
    width remSize(600px)
    font-size remSize(15px)
    color dark-gray
    
    & p
      margin-top 0
    
    & ul
      display flex
      flex-wrap wrap
      width 110%
      font-size remSize(14px)
      
      & li
        margin-bottom remSize(10px)
        padding-left remSize(16px)
        padding-right remSize(18px)
        position relative
        width 33.3333%
        box-sizing border-box
        
        &:after
          content ""
          position absolute
          top remSize(7px)
          left 0
          width remSize(6px)
          height @width
          background-color gray
          border-radius 50%
      
      & .sup
        font-size remSize(11px)
  
  &__item-btns
    display flex
    align-items center
  
  &__item-socials-list
    margin-right remSize(36px)
    width s("calc(100% - (%s + %s + %s))", remSize(36px), remSize(232px), remSize(132px))
    display flex
  
  &__item-social
    margin-right remSize(8px)
    position relative
    width remSize(28px)
    height @width
    background-color light-blue
    border-radius default-radius
    
    &:last-child
      margin-right 0
    
    &:hover
      background-color dark-blue
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
  
  &__item-btn
    margin-right remSize(16px)
    padding remSize(10px) remSize(18px) remSize(8px)
    font-size remSize(12px)
    
    &:last-child
      margin-right 0
  
  &__deadline
    margin-bottom remSize(16px)
    font-weight 700
  
  &__sub-info
    padding-right remSize(24px)
    width s("calc(100% - %s)", developer-width)
    box-sizing border-box
  
  &__developer-info
    padding-left remSize(24px)
    width developer-width
    border-left remSize(1px) solid light-gray
    box-sizing border-box
  
  &__developer
    margin-bottom remSize(36px)
    display flex
    align-items flex-start
  
  &__developer-img-wrapper
    position relative
    width developer-logo-width
    height @width
  
  &__developer-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__developer-text
    margin-left remSize(12px)
    width s("calc(100% - (%s + %s))", remSize(12px), developer-logo-width)
  
  &__developer-title
    margin-bottom remSize(8px)
    font-size remSize(15px)
    font-weight 700
  
  &__developer-name
    font-size remSize(14px)
    color light-blue
  
  &__developer-flats-title
    margin-bottom remSize(8px)
    line-height 1.3
    font-size remSize(15px)
    font-weight 700
  
  &__developer-flats-list
    margin-bottom remSize(24px)
    font-size remSize(13px)
    
    &:last-child
      margin-bottom 0
  
  &__developer-flats-item
    margin-bottom remSize(12px)
    line-height 1.2
    
    &:last-child
      margin-bottom 0
  
  &__developer-flats-item-title
    margin-right remSize(12px)
    display inline-block
    color light-blue
  
  &__business-info
    margin-bottom remSize(18px)
    font-size remSize(15px)
    color gray
    
    & .sup
      font-size remSize(12px)
  
  &__aside-top-info
    margin-bottom remSize(24px)
    display flex
    justify-content space-between
    align-items flex-end
  
  &__aside-likes
    margin-right remSize(12px)
    display flex
    align-items center
    line-height 0.8
    font-size remSize(15px)
    cursor pointer
    
    & .svg-icon
      margin-right remSize(6px)
      margin-bottom remSize(2px)
      width remSize(14px)
      height @width
      fill transparent
      stroke dark-blue
    
    &:hover
      color light-blue
      
      & .svg-icon
        stroke light-blue
  
  &__aside-id
    line-height 0.8
    color red
    text-transform uppercase