dr = default-radius

.offers-filter
  margin-bottom remSize(48px)
  
  &__title
    margin-bottom subtitle-margin-bottom
  
  &__form
    display flex
    align-items center
  
  &__selects
    margin-right remSize(48px)
    display flex
    align-items center
  
  &__select
    margin-left remSize(-1px)
    padding remSize(6px) remSize(16px) remSize(5px)
    position relative
    width remSize(110px)
    font-size remSize(15px)
    box-sizing border-box
    border remSize(1px) solid gray
    cursor pointer
    
    &:first-child
      border-radius dr 0 0 dr
    
    &:last-child
      border-radius 0 dr dr 0
  
  &__Select-title-wrapper
    overflow hidden
  
  &__select-selected
    white-space nowrap
  
  &__select-list-wrapper
    position absolute
    left remSize(-1px)
    bottom 0
    width remSize(140px)
    height 0
    transform translateY(100%)
    overflow hidden
  
  &__select-list
    padding remSize(6px) remSize(16px) remSize(5px)
    max-height remSize(300px)
    background-color white
    box-sizing border-box
    border remSize(1px) solid gray
    overflow-y auto
  
  &__select-list-item
    margin-bottom remSize(3px)
    
    &:last-child
      margin-bottom 0
    
    &:hover
      color light-blue
    
    &.is-active
      color light-blue
      cursor default
  
  &__intervals
    margin-right remSize(24px)
    display flex
    align-items center
  
  &__interval
    margin-right remSize(24px)
    display flex
    align-items center
    font-size remSize(15px)
    
    &:last-child
      margin-right 0
  
  &__interval-text
    margin-right remSize(8px)
  
  &__interval-input
    margin-right remSize(12px)
    width remSize(76px)
  
  &__submit
    padding remSize(9px) remSize(24px) remSize(8px)
    font-size remSize(11px)