$btn
  padding remSize(10px) remSize(24px) remSize(8px)
  font-size remSize(14px)
  color white
  letter-spacing remSize(1px)
  text-transform uppercase
  background-color light-blue
  border remSize(1px) solid light-blue
  border-radius default-radius
  box-sizing border-box
  cursor pointer
  outline none
  
  &:hover
    background-color dark-blue
    border-color dark-blue

.btn,
button
  @extend $btn

.btn
  
  &--dark
    background-color dark-blue
    border-color dark-blue
    
    &:hover
      background-color light-blue
      border-color light-blue
  
  &--light
    color light-blue
    background-color transparent
    
    &:hover
      color white
      background-color light-blue
      border-color light-blue
  
  &--white
    color light-blue
    background-color white
    border-color white
    
    &:hover
      color white
      background-color light-blue
      border-color light-blue