dr = default-radius

.card-offers
  
  &__list-item
    margin-bottom remSize(32px)
    display flex
    background-color white
    box-sizing border-box
    
    &:last-child
      margin-bottom 0
  
  &__item-img-wrapper
    position relative
    colWidth(2, 7)
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__item-info
    padding remSize(16px) remSize(24px) remSize(18px)
    colWidth(5, 7)
    border remSize(1px) solid light-gray
    border-left 0
    box-sizing border-box
  
  &__item-header
    margin-bottom remSize(16px)
    display flex
    justify-content flex-end
    flex-wrap wrap
    align-items center
    border-bottom remSize(1px) solid light-gray
    
    & .note
      margin-left remSize(8px)
      display inline-block
      font-size remSize(14px)
      font-weight 400
      color gray
  
  &__item-title
    margin-right remSize(36px)
    margin-bottom remSize(12px)
    display block
    width s("calc(100% - (%s + %s))", remSize(36px), remSize(100px))
    font-size remSize(20px)
    font-weight 700
    color light-blue
    
    &:hover
      color dark-blue
    
    & .sup
      font-size remSize(13px)
  
  &__item-id
    width remSize(100px)
    color red
    text-transform uppercase
  
  &__item-prices
    margin-right remSize(36px)
    margin-bottom remSize(16px)
    display flex
    align-items flex-end
    width s("calc(100% - (%s + %s))", remSize(320px), remSize(36px))
  
  &__item-price
    margin-right remSize(16px)
    font-size remSize(17px)
    font-weight 700
    
    & .rub
      margin-right remSize(4px)
      display inline-block
      width remSize(14px)
      height @width
  
  &__item-price-2
    font-size remSize(15px)
    
    & .rub
      margin-right remSize(4px)
      display inline-block
      width remSize(12px)
      height @width
    
    & .sup
      font-size remSize(11px)
  
  &__item-mortgage
    margin-bottom remSize(16px)
    width remSize(320px)
    font-size remSize(15px)
    font-weight 700
    color red
    text-align right
    text-decoration underline
    
    &:hover
      color dark-blue
      text-decoration none
  
  &__item-likes
    margin-bottom remSize(16px)
    display flex
    justify-content flex-end
    align-items center
    width remSize(320px)
    font-size remSize(14px)
    cursor pointer
    
    &:hover
      color light-blue
      
      & ^[0]__item-likes-icon
        stroke light-blue
  
  &__item-likes-icon
    margin-right remSize(8px)
    width remSize(15px)
    height @width
    fill transparent !important
    stroke dark-blue
  
  &__item-description
    margin-bottom remSize(28px)
    max-width 100%
    width remSize(600px)
    font-size remSize(15px)
    color dark-gray
    
    & p
      margin-top 0
    
    & ul
      display flex
      flex-wrap wrap
      width 110%
      font-size remSize(14px)
      
      & li
        margin-bottom remSize(10px)
        padding-left remSize(16px)
        padding-right remSize(18px)
        position relative
        width 33.3333%
        box-sizing border-box
        
        &:after
          content ""
          position absolute
          top remSize(7px)
          left 0
          width remSize(6px)
          height @width
          background-color gray
          border-radius 50%
      
      & .sup
        font-size remSize(11px)
  
  &__item-btns
    display flex
    align-items center
  
  &__item-socials-list
    margin-right remSize(36px)
    width s("calc(100% - (%s + %s + %s))", remSize(36px), remSize(232px), remSize(132px))
    display flex
  
  &__item-social
    margin-right remSize(8px)
    position relative
    width remSize(28px)
    height @width
    background-color light-blue
    border-radius default-radius
    
    &:last-child
      margin-right 0
    
    &:hover
      background-color dark-blue
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
  
  &__item-btn
    margin-right remSize(16px)
    padding remSize(10px) remSize(18px) remSize(8px)
    font-size remSize(12px)
    
    &:last-child
      margin-right 0