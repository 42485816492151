.img-snippet
  margin remSize(32px) 0 remSize(40px)
  display flex
  
  @media (max-width small-tablet)
    flex-wrap wrap

  &--left
    
    & ^[0]__img
      margin-right remSize(16px)
      order 0
      
      @media (max-width small-tablet)
        margin-right auto
    
    & ^[0]__content
      margin-left remSize(16px)
      order 2
      
      @media (max-width small-tablet)
        margin-left 0
  
  &--right
    
    & ^[0]__img
      margin-left remSize(16px)
      order 2
      
      @media (max-width small-tablet)
        margin-left auto
        order 0
    
    & ^[0]__content
      margin-right remSize(16px)
      order 0
      
      @media (max-width small-tablet)
        margin-right 0
        order 2

  &__img
    colWidth(3, 8, 16px)
    height 100%
    
    @media (max-width small-tablet)
      margin 0 auto remSize(24px)
      width auto
      height auto
      max-width 100%
      max-height 100%
  
  &__content
    colWidth(5, 8, 16px)
    
    @media (max-width small-tablet)
      width 100%