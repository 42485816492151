.banner
  position relative
  
  & .wrapper
    height 100%
  
  &__img-overlay
    fullSize()
    background-color img-overlay
    z-index 4
  
  &__img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    z-index 2
  
  &__text
    padding remSize(16px) remSize(48px) remSize(24px) remSize(24px)
    position absolute
    top 12%
    left 12%
    background-color alpha(white, 0.8)
    border-radius default-radius
    z-index 6
  
  &__title
    margin-bottom remSize(8px)
    font-size remSize(48px)
    font-weight 900
  
  &__slogan
    font-size remSize(24px)
    font-weight 700
    text-transform uppercase
    letter-spacing remSize(0.5px)