.preview-articles
  margin remSize(section-indent-top) 0 remSize(section-indent-bottom)
  
  &__list
    margin-left remSize(-10px)
    display flex
    flex-wrap wrap
    width s("calc(100% + (%s * 2))", remSize(10px))
    
    & .preview-article
      margin 0 remSize(10px) remSize(32px)
      colWidth(1, 3, 10px, 10px)
      
      @media (max-width large-tablet)
        margin 0 remSize(16px) remSize(32px)
        colWidth(1, 2, 16px, 16px)
      
      @media (max-width medium-tablet-2)
        margin 0 remSize(10px) remSize(32px)
        colWidth(1, 2, 10px, 10px)
      
      @media (max-width large-phone)
        margin 0 remSize(6px) remSize(24px)
        colWidth(1, 2, 6px, 6px)
  
  &__pagination
    margin-top remSize(20px)
    padding 0 remSize(10px)
    display flex
    justify-content flex-end
    
    @media (max-width medium-tablet)
      justify-content center
  
  &__pagination-item
    margin-right remSize(16px)
    position relative
    
    &.is-active
      color light-blue
      cursor default
      
      &:after
        color dark-text
    
    &.is-disabled
      color alpha(dark-text, 0.4)
      cursor default
      
      &:hover
        color alpha(dark-text, 0.4)
    
    &:hover
      color light-blue
      
      &:after
        color dark-text
    
    &:after
      content "|"
      position absolute
      top 0
      right remSize(-12px)
    
    &:last-child
      margin-right 0
    
    &:nth-last-child(2)
      
      &:after
        display none
    
    &--prev
      margin-right remSize(24px)
      
      &:after
        display none
    
    &--next
      margin-left remSize(18px)
      
      &:after
        display none