.offers-slider
  
  &__title-wrapper
    margin-bottom remSize(24px)
    padding-bottom remSize(16px)
    display flex
    justify-content space-between
    align-items center
    border-bottom remSize(1px) solid gray
  
  &__navigation
    display flex
  
  &__arr
    margin-right remSize(24px)
    position relative
    width remSize(24px)
    height @width
    cursor pointer
    
    &:last-child
      margin-right 0
  
  &__arr-icon
    centered()
    width 90%
    height @width
  
  &__slider
    margin-left remSize(-6px)
    padding remSize(6px)
    overflow hidden
  
  &__slider-wrapper
    position relative
    display flex
    width 100%
    height 100%
    box-sizing content-box
  
  &__slider-slide
    position relative
    flex-shrink 0
    
    &:hover
      box-shadow 0 0 remSize(6px) remSize(-2px) shadow
      
      & ^[0]__slide-title
        color light-blue
  
  &__slide-img-wrapper
    position relative
    display block
    width 100%
    height remSize(220px)
  
  &__slide-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__slide-info
    padding remSize(16px) remSize(12px) remSize(18px)
    background-color white
    border remSize(1px) solid light-gray
    border-top 0
  
  &__slide-title
    margin-right remSize(8px)
    margin-bottom remSize(12px)
    width s("calc(100% - (%s + %s))", remSize(8px), rang-width)
    font-weight 700
    color dark-text
    letter-spacing remSize(0.25px)
  
  &__slide-params
    font-size remSize(14px)
  
  &__slide-param
    margin-bottom remSize(6px)
    display flex
    align-items flex-end
    
    &.selected
      color red
      
      & ^[0]__slide-param-description
        color red
    
    & .sup
      font-size remSize(10px)
  
  &__slide-param-title
    margin-right param-title-indent-right
    width s("calc(100% - (%s + %s))", param-title-indent-right, param-description-width)
  
  &__slide-param-description
    width param-description-width
    color gray
    text-align right