.main-banner
  margin-top 0
  position relative
  min-height remSize(480px)
  height s("calc(100vh - %s)", remSize(94px))
  
  & .wrapper
    height 100%
  
  &__img-overlay
    fullSize()
    background-color img-overlay
    z-index 4
    
  &__img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    z-index 2

  &__title
    centered("top-center")
    top 18%
    max-width 100%
    width remSize(560px)
    font-size remSize(42px)
    color white
    letter-spacing remSize(1.5px)
    text-align center
    text-shadow remSize(2px) remSize(2px) remSize(3px) text-shadow
    z-index 6
  
  &__form
    centered("center")
    top 54%
    z-index 6
  
  &__form-inputs
    margin-bottom remSize(60px)
    display flex
    background-color alpha(white, 0.9)
    border-radius default-radius
    box-shadow remSize(2px) remSize(2px) remSize(4px) remSize(-1px) shadow
  
  &__form-input
    padding remSize(12px) remSize(16px)
    position relative
    display flex
    align-items center
    border-right remSize(1px) solid dark-blue
    
    &:last-child
      border-right 0
  
  &__form-input-title
    margin-right remSize(8px)
    font-weight 700
  
  &__select
    width remSize(120px)
    cursor pointer
    
    &--hide
      display none
    
      &.is-active
        display block
  
  &__select-title-wrapper
    overflow hidden
  
  &__select-selected
    font-weight 700
    white-space nowrap
    
  &__select-list-wrapper
    position absolute
    left 0
    bottom 0
    width remSize(180px)
    height 0
    background-color white
    transform translateY(100%)
    overflow hidden
  
  &__select-list
    padding remSize(12px) remSize(16px) remSize(14px)
    max-height remSize(300px)
    font-size remSize(15px)
    text-align center
    overflow-y auto
  
  &__select-list-item
    margin-bottom remSize(6px)
    text-align left
    cursor pointer
    
    &:last-child
      margin-bottom 0
    
    &:hover
      color light-blue
    
    &.is-active
      color light-blue
      cursor default
    
    &--label
      display flex
      align-items center
    
  &__select-list-item-input
    display none
    
    &:checked
      
      & + ^[0]__select-list-item-checkbox
        background-color light-blue
        
        & .svg-icon
          opacity 1
  
  &__select-list-item-checkbox
    margin-right remSize(8px)
    position relative
    width remSize(14px)
    height @width
    box-sizing border-box
    border remSize(1px) solid light-blue
    border-radius default-radius
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
      opacity 0
  
  &__select-list-item-apply
    margin-top remSize(12px)
    padding remSize(8px) remSize(14px) remSize(7px)
    display inline-block
    font-size remSize(11px)
  
  // &__form-input-select
  //   width remSize(90px)
    
  //   &.is-open
      
  //     & ^[0]__form-input-select-list
  //       display block

  // &__form-input-selected
  //   white-space nowrap
  //   cursor pointer
  //   overflow hidden
    
  //   &:hover
  //     color light-blue
  
  // &__form-input-select-list
  //   padding remSize(12px) remSize(12px) remSize(14px)
  //   position absolute
  //   right 0
  //   bottom 0
  //   display none
  //   width 100%
  //   background-color white
  //   transform translateY(100%)
  //   border remSize(1px) solid light-gray
  //   box-shadow 0 remSize(2px) remSize(4px) remSize(-1px) shadow
  //   box-sizing border-box
  
  // &__form-input-select-list-item
  //   margin-bottom remSize(4px)
  //   font-size remSize(15px)
  //   cursor pointer
    
  //   &:hover
  //     color light-blue
    
  //   &:last-child
  //     margin-bottom 0
    
  &__form-input-price
    padding 0
    padding-right remSize(6px)
    width remSize(90px)
    background-color transparent
    border 0
  
  &__form-btns-wrapper
    display flex
    justify-content center
  
  &__form-btn
    margin-right remSize(24px)
    padding remSize(12px) remSize(36px) remSize(10px)
    font-size remSize(18px)
    box-shadow remSize(2px) remSize(2px) remSize(3px) 0 shadow
    
    &:last-child
      margin-right 0