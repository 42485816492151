.views-info
  padding remSize(16px) 0 remSize(12px)
  letter-spacing remSize(0.25px)
  
  &__list
    display flex
    align-items center
  
  &__date
    margin-right remSize(24px)
  
  &__total-views
    margin-right remSize(6px)
    display flex
    align-items center
    color light-blue
  
  &__total-views-icon
    margin-right remSize(6px)
    width remSize(24px)
    height @width
    fill light-blue
  
  &__today-views
    color light-blue
  