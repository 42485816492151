.header-top
  padding remSize(10px) 0
  font-size remSize(14px)
  color white
  background-color dark-blue
  
  @media (max-width large-tablet)
    display none
  
  & .wrapper
    display flex
    justify-content space-between
    align-items center
  
  &__nav
    display flex
  
  &__nav-link
    margin-right remSize(12px)
    padding remSize(2px) 0
    color white
    letter-spacing remSize(0.5px)
    
    &:last-child
      margin-right 0
    
    &:hover
      opacity 0.6
    
    &.is-active
      box-sizing border-box
      border-bottom remSize(1px) solid white
      
      &:hover
        opacity 1
  
  &__login-registration
    font-weight 700
    color white
    letter-spacing remSize(1px)
    
    &:hover
      opacity 0.6