subsection-link-indent-right = remSize(8px)
subsection-count-width = remSize(50px)

.preview-sections
  
  &__list
    margin-left remSize(-16px)
    display flex
    width s("calc(100% + (%s * 2))", remSize(16px))
  
  &__item
    margin 0 remSize(16px)
    colWidth(1, 4, 16px, 16px)
  
  &__item-img-wrapper
    position relative
  
  &__item-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__item-info
    padding remSize(16px) remSize(16px) remSize(20px)
    background-color white
    border remSize(1px) solid light-gray
    border-top 0
  
  &__item-title
    margin-bottom remSize(14px)
    font-size remSize(16px)
    letter-spacing remSize(0.25px)
  
  &__item-title-link
    color dark-text
    
    &:hover
      color light-blue
  
  &__item-subsections
    font-size remSize(14px)
  
  &__item-subsection
    margin-bottom remSize(8px)
    display flex
    
    &:last-child
      margin-bottom 0
  
  &__item-subsection-link
    margin-right subsection-link-indent-right
    width s("calc(100% - (%s + %s))", subsection-link-indent-right, subsection-count-width)
    color light-blue
    
    &:hover
      color dark-blue
    
    & .selected
      font-weight 700
      color red
  
  &__item-subsection-count
    width subsection-count-width
    text-align right