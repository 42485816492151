.preview-article
  display block
  
  &__img-wrapper
    position relative
    overflow hidden
  
  &__img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
        
  &__info
    padding remSize(18px) remSize(14px) remSize(20px)
    background-color white
    border remSize(1px) solid light-gray
    border-top 0
    box-sizing border-box
    
    @media (max-width large-phone)
      padding remSize(14px) remSize(8px) remSize(18px)
  
  &__title
    margin-bottom remSize(6px)
    max-width 100%
    display block
    width remSize(320px)
    line-height 1.3
    font-weight 700
    letter-spacing remSize(0.25px)
    
    @media (max-width large-phone)
      font-size remSize(14px)
    
    &:hover
      color main
  
  &__section
    display block
    font-size remSize(12px)
    color light-blue
    
    &:hover
      color main
  
  &__info-data
    padding-top remSize(18px)
    display flex
    align-items center
    
    @media (max-width large-phone)
      flex-wrap wrap
  
  &__date
    width s("calc(100% - (%s * 2 + %s))", remSize(46px), remSize(16px))
    font-size remSize(12px)
    color dark-blue
    letter-spacing remSize(0.5px)
    
    @media (max-width large-phone)
      margin-bottom remSize(8px)
      width 100%
  
  &__info-data-item
    margin-right remSize(16px)
    display flex
    align-items center
    
    @media (max-width large-phone)
      margin-right remSize(12px)
    
    &:last-child
      margin-right 0
  
  &__info-data-item-icon
    margin-right remSize(8px)
    position relative
    
    @media (max-width large-phone)
      margin-right remSize(4px)
    
    &--like
      width remSize(14px)
      height @width
    
    &--view
      width remSize(20px)
      height remSize(18px)
    
    & .svg-icon
      fullSize()
  
  &__info-data-item-text
    font-size remSize(12px)