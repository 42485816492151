.article-title
  
  &__title
    margin-bottom remSize(20px)
    width remSize(800px)
    max-width 100%
    font-family header-font
    font-size remSize(36px)
    
    @media (max-width large-phone)
      font-size remSize(32px)
  
  &__info
    margin-bottom remSize(18px)
    display flex
    align-items center
    font-size remSize(13px)
    color dark-text
    
    @media (max-width large-phone)
      flex-wrap wrap
  
  &__section
    margin-right remSize(12px)
    display inline-block
    font-size remSize(17px)
    color dark-blue
    
    @media (max-width large-phone)
      margin-bottom remSize(12px)
      width 100%
    
    &:hover
      color light-blue
  
  &__date
    margin-right remSize(18px)
  
  &__views
    margin-right remSize(12px)
    display flex
    align-items center
  
  &__views-icon
    margin-right remSize(6px)
    position relative
    width remSize(26px)
    height remSize(22px)
    
    & .svg-icon
      fullSize()
      fill light-text
  
  &__likes
    display flex
    align-items center
  
  &__likes-icon
    margin-right remSize(4px)
    position relative
    width remSize(16px)
    height @width
    cursor pointer
    
    &:hover
      
      & .svg-icon
        fill light-blue
    
    & .svg-icon
      fullSize()
  
  &__share
    display flex
    flex-wrap wrap
    align-items center
  
  &__share-title
    margin-right remSize(10px)
    font-size remSize(14px)
    font-weight 700
    text-transform uppercase
  
  &__share-item
    margin-right remSize(8px)
    position relative
    width remSize(26px)
    height @width
    background-color dark-blue
    border-radius remSize(default-radius)
    cursor pointer
    
    &:last-child
      margin-right 0
      
    &:hover
      background-color light-blue
    
    & .svg-icon
      centered()
      width 56%
      height @width
      fill white