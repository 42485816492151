logo-width = remSize(220px)
nav-indent-left = remSize(36px)
nav-indent-right = remSize(24px)
lease-indent-right = remSize(48px)
lease-width = remSize(170px)
phone-width = remSize(164px)

.header-bottom
  padding remSize(16px) 0
  background-color white
  box-sizing border-box
  border-bottom remSize(1px) solid light-gray
  
  @media (max-width large-tablet)
    display none
  
  & .wrapper
    display flex
    align-items center
  
  &__logo
    width logo-width
    font-size remSize(32px)
    font-weight 900
  
  &__nav
    margin-left nav-indent-left
    margin-right nav-indent-right
    width s("calc(100% - (%s + %s + %s + %s + %s + %s))", logo-width, nav-indent-left, nav-indent-right, lease-width, lease-indent-right, phone-width)
    font-size remSize(17px)
  
  &__nav-link
    padding remSize(6px) remSize(8px)
    color dark-text
    
    @media (max-width small-desktop)
      font-size remSize(15px)
    
    &:hover
      color light-blue
    
    &.selected
      padding remSize(6px) remSize(24px)
      color white
      background-color light-blue
  
  &__lease-office
    margin-right lease-indent-right
    width lease-width
    text-align center
    text-transform none
  
  &__phone
    width phone-width
    font-size remSize(18px)
    font-weight 700
    text-align right
    letter-spacing remSize(1px)
    
    &:hover
      color light-blue