dr = default-radius

.page-description
  
  &__title
    margin-bottom remSize(24px)
    max-width 100%
    width remSize(900px)
    line-height 1.4
    font-size remSize(24px)
  
  &__tabs
    margin-bottom remSize(48px)
    display flex
    color white
  
  &__tab
    padding remSize(10px) remSize(20px)
    font-size remSize(14px)
    letter-spacing remSize(0.5px)
    background-color dark-blue
    cursor pointer
    
    &:first-child
      border-radius dr 0 0 dr
    
    &:last-child
      border-radius 0 dr dr 0
    
    &:hover
      background-color light-blue
    
    &.is-active
      background-color light-blue
      cursor default
  
  &__tabs-content
    max-width 100%
    width remSize(920px)
  
  &__tab-content
    display none
    font-size remSize(15px)
    
    & h2
      margin remSize(28px) 0 remSize(12px)
      font-size remSize(18px)
    
    & h3
      margin remSize(24px) 0 remSize(10px)
      font-size remSize(16px)
    
    & p
      margin remSize(8px) 0
      line-height 1.5
    
    &.is-active
      display block