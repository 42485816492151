dr = default-radius

.filter
  padding remSize(16px) 0 remSize(20px)
  background-color white
  box-shadow 0 0 remSize(3px) remSize(-1px) shadow
  
  &__top
    margin-bottom remSize(18px)
    display flex
    align-items center
  
  &__type-wrapper
    margin-right remSize(80px)
    display flex
    align-items center
    
  &__select
    margin-right remSize(-1px)
    padding remSize(6px) remSize(16px)
    position relative
    width remSize(140px)
    border remSize(1px) solid gray
    box-sizing border-box
    cursor pointer
    
    &:first-child
      border-radius dr 0 0 dr
    
    &:last-child
      border-radius 0 dr dr 0
    
    &.is-open
    
      & ^[0]__select-list-wrapper
        z-index 100
  
  &__select-wrapper-title
    overflow hidden
  
  &__select-selected
    white-space nowrap
  
  &__select-list-wrapper
    position absolute
    left remSize(-1px)
    bottom 0
    width remSize(220px)
    height 0
    transform translateY(100%)
    overflow hidden
    z-index -10
  
  &__select-list
    padding remSize(8px) remSize(16px) remSize(10px)
    width 100%
    font-size remSize(15px)
    background-color light-blue-2
    box-sizing border-box
    border remSize(1px) solid gray
  
  &__select-list-item
    margin-bottom remSize(4px)
    
    &:last-child
      margin-bottom 0
    
    &:hover
      color light-blue 
    
    &.is-active
      color light-blue
      cursor default
  
  &__interval-block
    margin-right remSize(32px)
    display flex
    align-items center
    
    &:last-child
      margin-right 0
  
  &__interval-text
    margin-right remSize(8px)
    color dark-text
  
  &__interval-input
    margin-right remSize(12px)
    width remSize(80px)
    
    &:last-child
      margin-right 0
  
  &__middle
    display flex
    align-items center
  
  &__place-wrapper
    display flex
  
  &__place
    margin-right remSize(-1px)
    padding remSize(6px) remSize(18px)
    position relative
    width remSize(140px)
    border remSize(1px) solid gray
    box-sizing border-box
    cursor pointer
    
    &:first-child
      border-radius dr 0 0 dr
    
    &:last-child
      border-radius 0 dr dr 0
  
  &__place-title-wrapper
    overflow hidden
  
  &__place-selected
    white-space nowrap

  &__place-list-wrapper
    position absolute
    left remSize(-1px)
    bottom 0
    width remSize(220px)
    height 0
    transform translateY(100%)
    overflow hidden
    z-index 10
  
  &__place-list
    padding remSize(12px) remSize(16px) remSize(14px)
    max-height remSize(300px)
    font-size remSize(15px)
    text-align center
    background-color white
    border remSize(1px) solid gray
    box-sizing border-box
    overflow auto
  
  &__place-list-item
    margin-bottom remSize(8px)
    display flex
    align-items center
    text-align left
    
    &:last-child
      margin-bottom 0
  
  &__place-list-item-input
    display none
    
    &:checked
      
      & + ^[0]__place-list-item-checkbox
        background-color light-blue
        
        & .svg-icon
          opacity 1
  
  &__place-list-item-checkbox
    margin-right remSize(8px)
    position relative
    width remSize(14px)
    height @width
    border-radius dr
    border remSize(1px) solid light-blue
    cursor pointer
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
      opacity 0
  
  &__place-apply
    margin-top remSize(8px)
    padding remSize(8px) remSize(14px) remSize(7px)
    display inline-block
    font-size remSize(11px)
  
  &__search
    margin-left remSize(48px)
    margin-right remSize(16px)
    width remSize(260px)
  
  &__submit
    padding remSize(9px) remSize(22px) remSize(7px)
    font-size remSize(12px)
  
  &__bottom
    margin-top remSize(32px)
  
  &__finded-places
    margin-bottom remSize(12px)
    display flex
    flex-wrap wrap
    max-width 100%
    width remSize(1240px)
    font-size remSize(15px)
  
  &__finded-place
    margin-right remSize(16px)
    margin-bottom remSize(14px)
    padding remSize(6px) remSize(16px)
    display flex
    align-items center
    background-color light-gray-2
    border-radius remSize(100px)
    
    &:last-child
      margin-right 0
  
  &__finded-place-color
    margin-right remSize(8px)
    width remSize(10px)
    height @width
    border-radius 50%
  
  &__finded-place-title
    margin-right remSize(10px)
  
  &__finded-place-remove
    position relative
    width remSize(20px)
    height @width
    border-radius 50%
    background-color white
    cursor pointer
    
    & .svg-icon
      centered()
      width 42%
      height @width
    
    &:hover
      background-color light-blue
      
      & .svg-icon
        fill white
  
  &__finded
    margin-bottom remSize(8px)
    width remSize(220px)
    font-size remSize(18px)
    color light-blue