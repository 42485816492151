aside-width = remSize(360px)
col-indent = remSize(16px)

.card-flat
  margin-top remSize(8px)
  
  & .wrapper
    display flex
    align-items flex-start
  
  &__col
    position relative
    
    &--main
      margin-right col-indent
      width s("calc(100% - (%s + (%s * 2)))", aside-width, col-indent)
    
    &--aside
      margin-left col-indent
      width aside-width
  
  &__preview-info
    margin-bottom remSize(32px)
    padding remSize(28px) remSize(32px) remSize(32px)
    position relative
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
  
  &__mortgage
    position absolute
    top remSize(28px)
    right remSize(32px)
    width remSize(150px)
    text-align center
  
  &__mortgage-img-wrapper
    position relative
    
  &__mortgage-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    background-color light-gray-2
  
  &__mortgage-info
    padding remSize(10px) remSize(8px) remSize(12px)
    border remSize(1px) solid light-gray
    border-top 0
    box-sizing border-box
  
  &__mortgage-title
    margin-bottom remSize(6px)
    line-height 1.2
    font-size remSize(14px)
  
  &__mortgage-more
    padding remSize(1px) 0
    display inline-block
    font-size remSize(13px)
    color light-blue
    border-bottom remSize(1px) dashed light-blue
    cursor pointer
    
    &:hover
      color dark-blue
      border-color transparent
  
  &__preview-title
    margin-bottom remSize(16px)
    max-width 72%
    font-size remSize(26px)
    font-weight 700
    color dark-text
    
  &__preview-place
    margin-bottom remSize(8px)
    padding-bottom remSize(2px)
    display inline-block
    color light-blue
    border-bottom remSize(1px) solid transparent
    
    &:hover
      color dark-blue
      border-color dark-blue
  
  &__preview-address-block
    margin-bottom remSize(10px)
    max-width 100%
    width remSize(680px)
    display flex
    align-items flex-start
    font-size remSize(15px)
  
  &__preview-address
    width s("calc(100% - (%s + %s))", remSize(16px), remSize(160px))
  
  &__preview-address-on-map
    margin-left remSize(16px)
    padding remSize(2px) 0
    width remSize(126px)
    font-size remSize(14px)
    color dark-blue
    text-align center
    border-bottom remSize(1px) dashed dark-blue
    cursor pointer
    
    &:hover
      color light-blue
      border-color light-blue
  
  &__preview-subway-block
    margin-bottom remSize(18px)
    display flex
    flex-wrap wrap
  
  &__preview-subway
    margin-right remSize(20px)
    display flex
    align-items flex-end
    font-size remSize(15px)
    
    &:last-child
      margin-right 0
  
  &__preview-subway-icon
    margin-right remSize(4px)
    margin-bottom remSize(-3px)
    width remSize(28px)
    height @width
    fill metro !important
  
  &__preview-subway-title
    margin-right remSize(8px)
  
  &__preview-subway-time
    font-size remSize(14px)
    color gray
  
  &__preview-functional-btns
    margin-bottom remSize(32px)
    display flex
    align-items center
  
  &__preview-likes
    margin-right remSize(24px)
    display flex
    align-items center
    font-size remSize(15px)
    cursor pointer
    
    &:hover
      color light-blue
      
      & ^[0]__preview-likes-icon
        stroke light-blue
  
  &__preview-likes-icon
    margin-right remSize(8px)
    margin-bottom remSize(4px)
    width remSize(18px)
    height @width
    fill transparent !important
    stroke dark-blue
  
  &__preview-download
    margin-right remSize(24px)
    display flex
    align-items center
    font-size remSize(15px)
    cursor pointer
    
    &:hover
      color light-blue
      
      & ^[0]__preview-download-icon
        fill light-blue
  
  &__preview-download-icon
    margin-right remSize(8px)
    margin-bottom remSize(4px)
    width remSize(18px)
    height @width
  
  &__preview-socials
    display flex
  
  &__preview-socials-item
    margin-right remSize(8px)
    position relative
    width remSize(28px)
    height @width
    background-color dark-blue
    border-radius default-radius
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
    
    &:last-child
      margin-right 0
    
    &:hover
      background-color light-blue
  
  &__preview-slider
    margin-bottom remSize(18px)
    position relative
    overflow hidden
    
    &:hover
      
      & ^[0]__preview-slider-navigation
        opacity 1
  
  &__preview-slider-navigation
    fullSize()
    opacity 0
  
  &__preview-slider-arr
    position absolute
    top 0
    width remSize(60px)
    height 100%
    background-color alpha(black, 0.4)
    z-index 10
    cursor pointer
    
    &--prev
      left 0
    
    &--next
      right 0
    
    & .svg-icon
      centered()
      width 60%
      height @width
      fill white
      opacity 0.8
    
    &:hover
      background-color alpha(black, 0.8)
      
      & .svg-icon
        opacity 1

  &__preview-slider-wrapper
    position relative
    display flex
    width 100%
    height 100%
    box-sizing content-box    

  &__preview-slider-slide
    position relative
    flex-shrink 0
    width 100%
    height remSize(480px)
  
  &__preview-slider-slide-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__preview-pagination-slider-block
    position relative
  
  &__preview-pagination-slider-nav
    fullSize()
  
  &__preview-pagination-slider-arr
    position absolute
    top 0
    width 3%
    height 100%
    cursor pointer
    
    &--prev
      left 0
    
    &--next
      right 0
    
    & .svg-icon
      centered()
      width 60%
      height @width
    
    &:hover
      
      & .svg-icon
        fill light-blue

  &__preview-pagination-slider
    margin 0 auto
    width 92%
    overflow hidden
  
  &__preview-pagination-slider-wrapper
    position relative
    display flex
    width 100%
    height 100%
    box-sizing content-box

  &__preview-pagination-slider-slide
    position relative
    flex-shrink 0
    height remSize(86px)
    box-sizing border-box
    border remSize(2px) solid dark-blue
    cursor pointer
    
    &:hover
      border-color alpha(light-blue, 0.6)
    
    &.is-active
      border-color light-blue
  
  &__preview-pagination-slider-slide-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
  
  &__detailed-info
    margin-bottom remSize(32px)
    padding remSize(28px) remSize(32px) remSize(32px)
    background-color white
    border remSize(1px) solid light-gray
  
  &__detailed-info-title
    margin-bottom remSize(24px)
  
  &__detailed-info-description
    margin-bottom remSize(36px)
    max-width 100%
    width remSize(720px)
    
    & p
      margin 0 0 remSize(8px)
  
  &__detailed-info-subtitle
    margin-bottom remSize(12px)
  
  &__detailed-info-table
    margin-bottom remSize(28px)
    max-width 100%
    width remSize(520px)
    font-size remSize(15px)
    
    & td
      padding remSize(4px) 0
  
  &__detailed-info-table-title
    width 46%
    font-weight 700
  
  &__detailed-info-table-description
    width 54%
  
  &__detailed-info-more-block
    margin-bottom remSize(16px)
    padding-top remSize(16px)
    display flex
    align-items flex-end
    border-top remSize(1px) solid light-gray
  
  &__detailed-info-more-text
    margin-right remSize(48px)
    width s("calc(100% - (%s + %s))", remSize(48px), remSize(200px))
  
  &__detailed-info-more-title
    margin-bottom remSize(8px)
  
  &__detailed-info-btn-wrapper
    width remSize(200px)
    text-align right
  
  &__map
    margin-bottom remSize(32px)
    padding remSize(28px) remSize(32px) remSize(32px)
    background-color white
    border remSize(1px) solid light-gray
  
  &__additional-info
    padding remSize(28px) remSize(32px) remSize(32px)
    background-color white
    border remSize(1px) solid light-gray
  
  &__additional-info-title
    margin-bottom remSize(24px)
  
  &__additional-info-list
    margin-bottom remSize(16px)
    display flex
    flex-wrap wrap
    max-width 100%
    width remSize(720px)
  
  &__additional-info-item
    margin-bottom remSize(16px)
    display flex
    align-items center
    width 50%
    
    & .sup
      font-size remSize(12px)
  
  &__additional-info-item-title
    margin-right remSize(12px)
    font-weight 700
  
  &__additional-info-advantages
    padding-top remSize(24px)
    border-top remSize(1px) solid light-gray
  
  &__additional-info-advantages-item
    margin-bottom remSize(8px)
    display flex
    align-items center
    
    & .svg-icon
      margin-right remSize(12px)
      width remSize(24px)
      height @width
      
  &__aside-info
    padding remSize(24px) remSize(18px) remSize(28px)
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
  
  &__aside-info-price
    margin-bottom remSize(4px)
    font-size remSize(24px)
    font-weight 700
    color light-blue
    letter-spacing remSize(0.5px)
  
  &__aside-info-price-2
    margin-bottom remSize(16px)
    padding-bottom remSize(12px)
    font-weight 700
    border-bottom remSize(1px) solid light-gray
    
    & .sup
      font-size remSize(12px)
  
  &__aside-info-title
    margin-bottom remSize(10px)
    max-width 90%
    font-size remSize(18px)
    font-weight 700
    color dark-text
    letter-spacing remSize(0.25px)
  
  &__aside-info-table
    margin-bottom remSize(32px)
    width 100%
    font-size remSize(15px)
    
    & td
      padding-top remSize(4px)
      padding-bottom @padding-top
      
      & .sup
        font-size remSize(11px)
  
  &__aside-info-table-title
    padding-right remSize(4px)
    width 44%
    font-weight 700
    box-sizing border-box
  
  &__aside-info-table-description
    padding-left remSize(4px)
    width 56%
    color light-blue
    box-sizing border-box
  
  &__aside-info-btns
    display flex
    flex-wrap wrap
  
  &__aside-info-btn
    margin-right remSize(16px)
    margin-bottom remSize(16px)
    padding remSize(10px) remSize(16px) remSize(8px)
    font-size remSize(12px)
    letter-spacing remSize(1.25px)
    
    &:last-child
      margin-right 0
  
  &__housing-complex
    margin-bottom remSize(32px)
    padding remSize(28px) remSize(32px) remSize(32px)
    position relative
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
  
  &__housing-complex-title
    margin-bottom remSize(18px)
  
  &__housing-complex-info-list
    display flex
    flex-wrap wrap
    align-items flex-end
    font-size remSize(15px)
  
  &__housing-complex-info-item
    margin-bottom remSize(10px)
    display flex
    width 50%
  
  &__housing-complex-info-item-title
    margin-right remSize(12px)
    max-width 46%
    width remSize(180px)
    color gray
  
  &__contract
    padding remSize(28px) remSize(32px) remSize(32px)
    position relative
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
  
  &__contract-title
    margin-bottom remSize(12px)
  
  &__contract-note
    margin-bottom remSize(24px)
    font-size remSize(15px)
  
  &__contract-form
    max-width 100%
    width remSize(420px)
    display flex
  
  &__contract-input
    margin-right remSize(16px)
    width s("calc(100% - (%s + %s))", remSize(14px), remSize(146px))
  
  &__contract-submit
    width remSize(146px)
    text-align center
  
  &__aside-complex-info
    margin-bottom remSize(24px)
  
  &__aside-complex-info-item
    margin-bottom remSize(8px)
    font-size remSize(15px)
  
  &__aside-developer
    margin-bottom remSize(24px)
    padding-bottom remSize(16px)
    display flex
    align-items center
    border-bottom remSize(1px) solid light-gray
  
  &__aside-developer-text
    margin-right remSize(16px)
    width s("calc(100% - (%s + %s))", remSize(16px), remSize(76px))
  
  &__aside-developer-title
    margin-bottom remSize(4px)
    font-size remSize(18px)
    font-weight 700
    color dark-text
  
  &__aside-developer-name
    color light-blue
  
  &__aside-developer-img-wrapper
    position relative
    width remSize(76px)
  
  &__aside-developer-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover