subsection-link-indent-right = remSize(8px)
subsection-count-width = remSize(50px)

.preview-maps
  
  & .wrapper
    display flex
  
  &__map
    margin-right remSize(16px)
    padding remSize(48px) remSize(36px) remSize(72px)
    position relative
    colWidth(1, 2, 16px)
    color white
    text-align center
    
  &__map-overlay
    fullSize()
    background-color img-overlay
    z-index 4
  
  &__map-img
    fullSize()
    background-repeat no-repeat
    background-position 50%
    background-size cover
    z-index 2
  
  &__title
    margin-bottom remSize(16px)
    padding 0 remSize(16px)
    position relative
    font-size remSize(32px)
    color white
    letter-spacing remSize(1px)
    text-shadow remSize(2px) remSize(2px) remSize(3px) text-shadow
    z-index 6
  
  &__note
    margin 0 auto remSize(36px)
    position relative
    max-width 90%
    width remSize(360px)
    font-size remSize(18px)
    text-shadow remSize(1px) remSize(1px) remSize(2px) text-shadow
    z-index 6
  
  &__find-on-map
    position relative
    z-index 6
  
  &__sections
    margin-left remSize(16px)
    display flex
    colWidth(1, 2, 16px)
  
  &__sections-item
    padding remSize(16px) remSize(16px) remSize(20px)
    colWidth(1, 2, 16px)
    background-color white
    border remSize(1px) solid light-gray
    box-sizing border-box
    
    &:first-child
      margin-right remSize(16px)
    
    &:last-child
      margin-left remSize(16px)
  
  &__sections-item-title
    margin-bottom remSize(14px)
    font-size remSize(16px)
    letter-spacing remSize(0.25px)
  
  &__sections-item-title-link
    color dark-text
    
    &:hover
      color light-blue
  
  &__sections-item-subsections
    font-size remSize(14px)
  
  &__sections-item-subsection
    margin-bottom remSize(8px)
    display flex
    
    &:last-child
      margin-bottom 0
  
  &__sections-item-subsection-link
    margin-right subsection-link-indent-right
    width s("calc(100% - (%s + %s))", subsection-link-indent-right, subsection-count-width)
    color light-blue
    
    &:hover
      color dark-blue
    
    & .selected
      font-weight 700
      color red
  
  &__sections-item-subsection-count
    width subsection-count-width
    text-align right